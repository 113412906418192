<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import Avatar from 'primevue/avatar';
    import Button from 'primevue/button';
    import TieredMenu from 'primevue/tieredmenu';
    import Dialog from 'primevue/dialog';
    import PanelMenu from 'primevue/panelmenu';
    import { useAuthenticationAction, useAuthenticationQuery } from '@/composables/authentication';
    import { useCompanyAction, useCompanyQuery } from '@/composables/company';
    import AccountInfoDisplay from './AccountInfoDisplay.vue';
    import type { MenuItem } from 'primevue/menuitem';
    import { isMobile } from '@/util/breakpoints';
    import { useLeadAction, useLeadQuery } from '@/account/composables/leads';
    import { componentStyle } from '@/styling/util/component-style';
    import { buttonFontWeight500 } from '@/styling/components/button';

    const props = withDefaults(
        defineProps<{
            menuItems?: MenuItem[] | undefined;
            showDropDownIndicator?: boolean;
        }>(),
        {
            menuItems: undefined,
            showDropDownIndicator: true,
        }
    );

    const { t } = useI18n();
    const { isUserLoggedIn, username } = useAuthenticationQuery();
    const { companyName, isRetailer } = useCompanyQuery();
    const authenticationAction = useAuthenticationAction();
    const companyAction = useCompanyAction();
    const leadAction = useLeadAction();
    const { amountNewLeads } = useLeadQuery();

    const accountMenu = ref();
    const showMobileDialog = ref(false);

    function hideMobileDialog(): void {
        showMobileDialog.value = false;
    }

    const task = useAsyncTask(async () => {
        await authenticationAction.fetchCurrentCustomerIfMissing.perform();
        if (isUserLoggedIn.value) {
            await leadAction.fetchAmountNewLeads();
        }
    }).perform();

    watch(
        isUserLoggedIn,
        async (newIsUserLoggedIn) => {
            if (newIsUserLoggedIn) {
                await companyAction.findCustomerCompanyIfMissing();
            }
        },
        { immediate: true }
    );

    const accountMenuItems = computed(() =>
        props.menuItems != null && props.menuItems.length > 0
            ? props.menuItems
            : [
                  {
                      separator: true,
                  },
                  {
                      label: t('ACCOUNT.MY_USER_AREA'),
                      icon: 'pi pi-user',
                      command: async () => {
                          await router.push({ name: Navigation.Account });
                      },
                  },
                  {
                      separator: true,
                  },
                  {
                      label: t('ACCOUNT.OVERVIEW_VIEW.OFFERS'),
                      command: async () => {
                          await router.push({ name: Navigation.OfferHistory });
                      },
                  },
                  {
                      label: t('ACCOUNT.OVERVIEW_VIEW.ORDERS'),
                      command: async () => {
                          await router.push({ name: Navigation.OrderHistory });
                      },
                  },
                  !isRetailer.value
                      ? undefined
                      : {
                            label:
                                amountNewLeads.value > 0
                                    ? `${t('ACCOUNT.OVERVIEW_VIEW.LEADS')} (${amountNewLeads.value})`
                                    : t('ACCOUNT.OVERVIEW_VIEW.LEADS'),
                            command: async () => {
                                await router.push({ name: Navigation.Leads });
                            },
                        },
                  {
                      separator: true,
                  },
                  {
                      label: t('LOGIN.LOGOUT'),
                      command: async () => {
                          await authenticationAction.logout();
                          await router.push({ name: Navigation.SalesProductList });
                      },
                  },
              ].filter((item) => item != null)
    );

    const tieredMenuStyle = componentStyle({
        itemPadding: '1rem',
        itemBorderRadius: '0',
    });
</script>

<template>
    <template v-if="task.isSuccessful">
        <Button
            v-if="!isUserLoggedIn"
            data-testid="login-register-button"
            :label="!isMobile ? t('ACCOUNT.LOGIN_REGISTER') : ''"
            icon="pi pi-user"
            :icon-pos="isMobile ? 'left' : 'right'"
            class="text-base-semibold-line-height-auto"
            :dt="buttonFontWeight500"
            @click="router.push({ name: Navigation.Login })"
        />
        <template v-else-if="isMobile">
            <Button class="account-button-mobile" @click="() => (showMobileDialog = true)">
                <Avatar :label="username[0]" class="avatar" shape="circle" />
            </Button>
            <Dialog
                v-model:visible="showMobileDialog"
                modal
                :show-header="false"
                class="p-dialog-maximized"
                :pt="{
                    content: { style: 'padding: 0;' },
                }"
            >
                <div class="nav-dialog-container">
                    <div class="dialog-header">
                        <Button
                            icon="pi pi-times"
                            text
                            rounded
                            class="close-button"
                            severity="secondary"
                            @click="hideMobileDialog()"
                        />
                        <span class="text-xl-semibold-line-height-auto dialog-header-title">{{
                            t('NAVIGATION.MOBILE_CUSTOMER_ACCOUNT')
                        }}</span>
                    </div>
                    <div class="dialog-content">
                        <AccountInfoDisplay :company-name="companyName" :user-name="username" />
                        <PanelMenu id="account-button-panel-menu" :model="accountMenuItems">
                            <template #item="{ item, active }">
                                <div v-if="item.separator" class="mobile-divider" />
                                <div v-else class="mobile-panel-item">
                                    <router-link
                                        :to="{ name: item.route }"
                                        class="text-base-regular-line-height-auto link-reset mobile-panel-item-link"
                                        @click="
                                            async (event: any) => {
                                                hideMobileDialog();
                                                if (item.command != null) {
                                                    await item.command(event);
                                                }
                                            }
                                        "
                                    >
                                        <span v-if="item.icon" :class="item.icon" class="mobile-panel-item-icon" />
                                        <span>{{ item.label }}</span>
                                    </router-link>
                                    <span
                                        v-if="item.items"
                                        class="right pi"
                                        :class="active ? 'pi-angle-up' : 'pi-angle-down'"
                                    />
                                </div>
                            </template>
                        </PanelMenu>
                    </div>
                </div>
            </Dialog>
        </template>
        <template v-else>
            <Button
                :aria-haspopup="true"
                aria-controls="account-button-tiered-menu"
                data-testid="account-button"
                @click="(event) => accountMenu.toggle(event)"
            >
                <div class="account-button-content">
                    <div class="name-container">
                        <div class="name-with-ellipsis text-md-regular-line-height-auto">
                            {{ companyName }}
                        </div>
                        <div class="name-with-ellipsis text-base-semibold-line-height-auto">
                            {{ username }}
                        </div>
                    </div>
                    <Avatar :label="username[0]" class="avatar" shape="circle" />
                    <i v-if="showDropDownIndicator" class="pi pi-angle-down" />
                </div>
            </Button>
            <TieredMenu
                id="account-button-tiered-menu"
                ref="accountMenu"
                :dt="tieredMenuStyle"
                :model="accountMenuItems"
                popup
            >
                <template #start>
                    <AccountInfoDisplay :company-name="companyName" :user-name="username" />
                </template>
            </TieredMenu>
        </template>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .close-button {
        color: main.$color-white;

        &:hover {
            background-color: main.$color-primary-600;
        }
    }

    .account-button-mobile {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .account-button-content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: main.$spacing-4;
    }

    .avatar {
        background-color: main.$color-white;
        color: main.$color-primary-500;
    }

    .link-reset {
        text-decoration: none;
        color: main.$color-text;
    }

    .mobile-divider {
        border-top: 1px solid main.$color-border-gray;
        padding-top: main.$spacing-3;
        margin-top: main.$spacing-3;
        background-color: main.$color-white;
    }

    .mobile-panel-item {
        display: flex;
        gap: main.$spacing-3;
        align-items: center;
        cursor: pointer;
        background: main.$color-white;
        color: main.$color-text;
    }

    .mobile-panel-item-link {
        padding: main.$spacing-4;
        text-decoration: none;
        color: main.$color-text;
        width: 100%;

        &:hover {
            background-color: main.$color-background-bluegray;
        }
    }

    .mobile-panel-item-icon {
        padding-right: main.$spacing-3;
    }

    :deep(.p-toggleable-content) .mobile-panel-item {
        padding-left: main.$spacing-6;
    }

    :deep(.p-panelmenu) {
        .p-panelmenu-content,
        .p-panelmenu-header-content {
            border: none;
        }
    }

    .nav-dialog-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .dialog-header {
        width: 100%;
        height: 56px;
        min-height: 56px;
        display: flex;
        align-items: center;
        gap: main.$spacing-5;
        padding: main.$spacing-3 main.$spacing-5;
        background: main.$color-primary-500;
        color: main.$color-text;
        border-bottom: 1px solid main.$color-border-gray;

        .pi {
            width: 40px;
            text-align: center;
        }
    }

    .dialog-header-title {
        color: main.$color-white;
    }

    .dialog-content {
        padding: main.$spacing-3 0;
    }

    .right {
        display: flex;
        margin-left: auto;
    }

    .name-with-ellipsis {
        max-width: 192px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .name-container {
        text-align: right;
    }
</style>
